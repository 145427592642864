<template>
  <div class="container" style="padding-top: 100px; padding-bottom: 100px">
    <p class="p1"><strong>Conditions d&apos;utilisation</strong></p>
    <p class="p2">
      Merci d&apos;avoir rejoint Edays (&quot;Nous&quot;, &quot;Nous&quot;,
      &quot;Soci&eacute;t&eacute;&quot;). Ces conditions d&apos;utilisation,
      ainsi que la politique de confidentialit&eacute; et tout autre document
      qu&apos;ils incorporent express&eacute;ment, d&eacute;finissent les termes
      et conditions selon lesquels vous pouvez acc&eacute;der et utiliser les
      services propos&eacute;s par Edays, l&apos;application mobile et le
      service fourni ou exploit&eacute; par Edays (&quot;Service
      &quot;,&quot;Conditions d&apos;utilisation&quot; ou &quot;Accord&quot;)
    </p>
    <p class="p3"><br /></p>
    <p class="p2">
      <strong
        >VEUILLEZ LIRE ATTENTIVEMENT LES CONDITIONS D&apos;UTILISATION AVANT DE
        COMMENCER &Agrave; UTILISER LE SERVICE. EN UTILISANT LE SERVICE, VOUS
        D&Eacute;CLAREZ AVOIR AU MOINS 16 ANS. DE PLUS, VOUS ACCEPTEZ ET
        ACCEPTEZ D&apos;&Ecirc;TRE LI&Eacute; ET DE RESPECTER CES CONDITIONS
        D&apos;UTILISATION. SI VOUS N&apos;AVEZ PAS 16 ANS OU QUE VOUS NE VOULEZ
        PAS ACCEPTER CES CONDITIONS D&apos;UTILISATION, VOUS NE DEVEZ PAS
        ACC&Eacute;DER OU UTILISER LE SERVICE. NOUS NOUS R&Eacute;SERVONS LE
        DROIT DE MODIFIER, D&apos;AJOUTER OU DE SUPPRIMER DES PARTIES DE CES
        CONDITIONS D&apos;UTILISATION ET DE TOUT DOCUMENT INT&Eacute;GR&Eacute;
        AUX PR&Eacute;SENTES &Agrave; TOUT MOMENT ET &Agrave; NOTRE SEULE
        DISCR&Eacute;TION. VOTRE UTILISATION CONTINUE DU SERVICE APR&Egrave;S LA
        PUBLICATION DE TOUTE MODIFICATION SIGNIFIE QUE VOUS ACCEPTEZ ET ACCEPTEZ
        CES MODIFICATIONS. IL EST DE VOTRE RESPONSABILIT&Eacute; DE
        V&Eacute;RIFIER P&Eacute;RIODIQUEMENT CES CONDITIONS D&apos;UTILISATION
        POUR LES CHANGEMENTS.</strong
      >
    </p>
    <p class="p1 mt-2"><strong>Utilisation du Service</strong></p>
    <p class="p1 mt-2"><strong>G&eacute;n&eacute;ral</strong></p>
    <p class="p2">
      Sous r&eacute;serve des termes et conditions du pr&eacute;sent Accord, et
      pendant la dur&eacute;e du pr&eacute;sent Accord, Edays vous accorde un
      droit non exclusif et non transf&eacute;rable d&apos;acc&eacute;der et
      d&apos;utiliser le Service conform&eacute;ment aux termes du
      pr&eacute;sent Accord, et toute documentation mise &agrave; disposition
      &agrave; vous par Edays uniquement aux fins de votre utilisation
      personnelle des Services. Le Service est activ&eacute; par la technologie,
      les logiciels et certains contenus fournis par voie &eacute;lectronique.
      Vous vous engagez &agrave; ne pas r&eacute;tro concevoir,
      d&eacute;compiler, d&eacute;sassembler, traduire ou tenter d&apos;une
      autre mani&egrave;re d&apos;acc&eacute;der au code source de tout aspect
      des Services, y compris toute technologie, logiciel et contenu. Vous ne
      copierez, reproduirez, alt&eacute;rerez, ne cr&eacute;erez pas
      d&apos;&oelig;uvres d&eacute;riv&eacute;es &agrave; partir de, ou
      modifierez autrement les Services, y compris toute technologie, logiciel
      et contenu. Vous ne ferez pas de location, de pr&ecirc;t, sous-licencier,
      distribuer ou autrement fournir &agrave; d&apos;autres l&apos;acc&egrave;s
      &agrave; ou avec tout aspect des Services. En dehors de ce qui est
      express&eacute;ment stipul&eacute; dans le pr&eacute;sent Contrat, aucune
      licence ni aucun autre droit sur les Services ne vous est accord&eacute;,
      et toutes ces licences et tous ces droits sont express&eacute;ment
      r&eacute;serv&eacute;s par les pr&eacute;sentes.
    </p>
    <p class="p3"><br /></p>
    <p class="p2">
      Le Service a &eacute;t&eacute; con&ccedil;u par Edays dans le but
      d&apos;aider les gens &agrave; se motiver gr&acirc;ce &agrave; des
      citations quotidiennes et source de d&eacute;veloppement personnel. Le
      Service n&eacute;cessite votre engagement et votre participation actifs.
      Vous comprenez que, malgr&eacute; vos efforts, les r&eacute;sultats des
      utilisateurs individuels varieront pour diverses raisons et Edays ne peut
      garantir que vous atteindrez vos objectifs.
    </p>
    <p class="p3"><br /></p>
    <p class="p1"><strong>Inscription</strong></p>
    <p class="p2">
      Vous devez vous inscrire au Service et fournir certaines informations vous
      concernant. Toutes les informations que nous recueillons &agrave; votre
      sujet dans le cadre des Services sont soumises &agrave; notre Politique de
      confidentialit&eacute;. En utilisant le Service, vous consentez &agrave;
      toutes les actions que nous entreprenons concernant vos informations
      conform&eacute;ment &agrave; la Politique de confidentialit&eacute;, y
      compris le fait d&apos;&ecirc;tre contact&eacute; par e-mail ou SMS.
    </p>
    <p class="p3"><br /></p>
    <p class="p2">
      Vous recevrez un compte d&apos;utilisateur pour notre usage personnel qui
      n&eacute;cessite un e-mail ou num&eacute;ro de t&eacute;l&eacute;phone et
      un mot de passe. Vous &ecirc;tes responsable de toutes les
      activit&eacute;s qui se produisent sous votre compte d&apos;utilisateur.
      Vous devez&nbsp;: (I) &ecirc;tre seul responsable de l&apos;exactitude, de
      la qualit&eacute;, de l&apos;int&eacute;grit&eacute;, de la
      l&eacute;galit&eacute;, de la fiabilit&eacute; et de la pertinence de
      toutes les donn&eacute;es que vous fournissez&nbsp;; (II) maintenir la
      confidentialit&eacute; de votre mot de passe et des informations de votre
      compte utilisateur&nbsp;; (III) d&eacute;ployer des efforts
      commercialement raisonnables pour emp&ecirc;cher l&apos;acc&egrave;s ou
      l&apos;utilisation non autoris&eacute;s du Service, et informer rapidement
      la Soci&eacute;t&eacute; d&apos;une telle utilisation non
      autoris&eacute;e&nbsp;; et (IV) se conformer &agrave; toutes les lois
      locales, &eacute;tatiques et f&eacute;d&eacute;rales applicables lors de
      l&apos;utilisation du Service.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Dur&eacute;e et r&eacute;siliation</strong></p>
    <p class="p2">
      La dur&eacute;e du pr&eacute;sent Accord commencera d&egrave;s votre
      inscription r&eacute;ussie au Service et se poursuivra ind&eacute;finiment
      &agrave; moins qu&apos;elle ne soit r&eacute;sili&eacute;e par l&apos;une
      ou l&apos;autre des parties, comme le permettent les pr&eacute;sentes. La
      Soci&eacute;t&eacute; peut suspendre ou r&eacute;silier votre acc&egrave;s
      au Service si vous (a) enfreignez l&apos;une des conditions du
      pr&eacute;sent Accord, ou (b) adopter une conduite que la
      Soci&eacute;t&eacute; d&eacute;termine, &agrave; sa discr&eacute;tion,
      susceptible d&apos;avoir un effet n&eacute;gatif sur la
      Soci&eacute;t&eacute; ou sa r&eacute;putation. En cas de
      r&eacute;siliation, vous n&apos;aurez plus acc&egrave;s au Service,
      &agrave; l&apos;application mobile ou au contenu fourni par le biais des
      Services. En plus de la r&eacute;siliation, la Soci&eacute;t&eacute; se
      r&eacute;serve le droit d&apos;exercer tous les recours &agrave; sa
      disposition.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Inscription</strong></p>
    <p class="p2">
      Vous fournirez &agrave; notre Soci&eacute;t&eacute; des informations
      d&apos;inscription exactes, compl&egrave;tes et mises &agrave; jour. Ne
      pas le faire constituera une violation des pr&eacute;sentes conditions, ce
      qui peut entra&icirc;ner la r&eacute;siliation imm&eacute;diate de votre
      compte d&apos;entreprise. Vous ne devez pas utiliser une fausse nature,
      adresse e-mail ou num&eacute;ro de t&eacute;l&eacute;phone ou saisir des
      informations dans le but de vous faire passer pour une autre personne.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Propri&eacute;t&eacute; intellectuelle</strong></p>
    <p class="p2">
      &Agrave; l&apos;exception de la licence limit&eacute;e et des droits
      d&apos;utilisation qui vous sont express&eacute;ment accord&eacute;s en
      vertu des pr&eacute;sentes Conditions d&apos;utilisation pendant la
      Dur&eacute;e, tous les titres et droits sur les Services, y compris toute
      technologie, logiciel et contenu, y compris les droits de
      propri&eacute;t&eacute; sur les brevets (enregistrements, renouvellements
      et demandes en attente), les droits d&apos;auteur, les marques de
      commerce, les secrets commerciaux, le mat&eacute;riel de la
      Soci&eacute;t&eacute; ou de tiers, toute autre technologie, tout
      d&eacute;riv&eacute; et toute la survaleur associ&eacute;e &agrave; ce qui
      pr&eacute;c&egrave;de sont la propri&eacute;t&eacute; exclusive de la
      Soci&eacute;t&eacute; et/ou de tiers.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Confidentialit&eacute;</strong></p>
    <p class="p2">
      Nous utiliserons et prot&eacute;gerons vos informations personnelles
      conform&eacute;ment &agrave; la politique de confidentialit&eacute;. Vous
      acceptez de ne pas r&eacute;v&eacute;ler ou divulguer des informations
      confidentielles de la Soci&eacute;t&eacute;, y compris des informations,
      des documents et du contenu fournis en relation avec le Service) &agrave;
      quelque fin que ce soit avec le consentement de la Soci&eacute;t&eacute;.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Informations non confidentielles</strong></p>
    <p class="p2">
      Sous r&eacute;serve des termes et conditions applicables
      &eacute;nonc&eacute;s dans notre politique de confidentialit&eacute;,
      toute autre communication ou tout autre mat&eacute;riel que vous nous
      envoyez via le service, tels que des questions, des commentaires, des
      suggestions ou autres, est et sera consid&eacute;r&eacute; comme non-
      confidentielles et nous n&apos;aurons aucune obligation d&apos;aucune
      sorte &agrave; l&apos;&eacute;gard de ces informations. Nous serons libres
      d&apos;utiliser les id&eacute;es, concepts, savoir-faire ou techniques
      contenus dans une telle communication &agrave; quelque fin que ce soit, y
      compris, mais sans s&apos;y limiter, le d&eacute;veloppement, la
      fabrication et la commercialisation de produits et services.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Teneur</strong></p>
    <p class="p2">
      Les informations pr&eacute;sent&eacute;es sur ou via le Service sont mises
      &agrave; disposition uniquement &agrave; des fins d&apos;information
      g&eacute;n&eacute;rale. Nous ne garantissons pas l&apos;exactitude,
      l&apos;exhaustivit&eacute; ou l&apos;utilit&eacute; de ces informations.
      Toute confiance que vous accordez &agrave; ces informations est
      strictement &agrave; vos risques et p&eacute;rils. Ce service peut fournir
      des informations sur le bien-&ecirc;tre, l&rsquo;entreprenariat et
      processus de mise en action. Cependant, vous &ecirc;tes seul responsable
      de vos d&eacute;cisions et actions et nous d&eacute;clinons toute
      responsabilit&eacute; d&eacute;coulant de celles-ci.
    </p>
    <p class="p3"><br /></p>
    <p class="p1">
      <strong>Acc&egrave;s aux services et modifications</strong>
    </p>
    <p class="p2">
      Nous nous r&eacute;servons le droit de retirer ou de modifier ce Service
      ou les documents que nous fournissons en relation avec le Service,
      &agrave; notre seule discr&eacute;tion. Nous ne serons pas responsables
      si, pour une raison quelconque, tout ou partie du Service est indisponible
      &agrave; tout moment ou pour toute p&eacute;riode. De temps &agrave;
      autre, nous pouvons restreindre l&apos;acc&egrave;s &agrave; certaines
      parties du Service ou &agrave; l&apos;ensemble du Service aux
      utilisateurs. Nous pouvons &eacute;galement mettre &agrave; jour le
      contenu de ce service de temps &agrave; autre, et nous faisons des efforts
      raisonnables pour maintenir les informations &agrave; jour. Cependant,
      nous ne pouvons pas garantir que le contenu est complet ou &agrave; jour.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Marques de commerce</strong></p>
    <p class="p2">
      Les marques de commerce, marques de service, graphiques et logos
      utilis&eacute;s dans le cadre du Service sont des marques de commerce de
      leurs propri&eacute;taires respectifs. Aucun droit ou licence ne vous est
      accord&eacute; &agrave; l&rsquo;&eacute;gard de l&rsquo;une des marques de
      commerce mentionn&eacute;es ci-dessus et de toute utilisation de ces
      marques de commerce. Vous reconnaissez et acceptez que tous les textes,
      graphiques, photographies, marques de commerce, logos, interfaces
      visuelles, illustrations, codes informatiques et tout autre contenu
      connexe contenu sur le Service sont la propri&eacute;t&eacute; de la
      Soci&eacute;t&eacute; ou de tiers et sont prot&eacute;g&eacute;s par les
      lois sur l&rsquo;habillage commercial, le droit d&rsquo;auteur, les
      brevets et les marques de commerce, et divers autres droits de
      propri&eacute;t&eacute; intellectuelle et lois sur la concurrence
      d&eacute;loyale. Toute reproduction, publication, distribution
      ult&eacute;rieure ou exposition publique de mat&eacute;riel fourni par le
      biais du Service, en tout ou en partie, est strictement interdite. Sauf
      disposition expresse dans les pr&eacute;sentes Conditions
      d&rsquo;utilisation, aucune partie du Service et aucun contenu ne peuvent
      &ecirc;tre copi&eacute;s, reproduits, republi&eacute;s,
      t&eacute;l&eacute;charg&eacute;s, affich&eacute;s, affich&eacute;s
      publiquement, encod&eacute;s, traduits, distribu&eacute;s ou transmis de
      quelque mani&egrave;re que ce soit (y compris la &laquo; mise en miroir
      &raquo;) &agrave; tout autre ordinateur, serveur, Service ou autre support
      pour publication ou distribution ou pour toute entreprise commerciale,
      sans le consentement &eacute;crit pr&eacute;alable expr&egrave;s de la
      Soci&eacute;t&eacute;.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Liens et technologie tiers</strong></p>
    <p class="p2">
      Toutes les technologies ou tous les services tiers fournis, mis &agrave;
      disposition, li&eacute;s ou autrement accessibles via le service
      (&quot;technologie tierce&quot;) sont fournis uniquement pour votre
      commodit&eacute; et ne sont pas sous notre contr&ocirc;le. La
      Soci&eacute;t&eacute; n&apos;approuve, ne recommande ou ne fait aucune
      d&eacute;claration ou garantie concernant toute technologie tierce. La
      Soci&eacute;t&eacute; n&apos;a aucune responsabilit&eacute; envers vous
      pour toute technologie tierce &agrave; laquelle vous acc&eacute;dez et
      vous l&apos;utilisez &agrave; vos risques et p&eacute;rils. De plus, vous
      acceptez de vous conformer &agrave; tous les termes et conditions
      applicables &agrave; l&apos;utilisation de la technologie tierce et de
      vous assurer que vous avez obtenu tous les droits, licences et
      autorisations qui peuvent &ecirc;tre n&eacute;cessaires pour utiliser
      cette technologie tierce.
    </p>
    <p class="p4"><br /></p>
    <p class="p1">
      <strong>Destin&eacute; aux utilisateurs de plus de 16 ans</strong>
    </p>
    <p class="p2">
      Le Service n&apos;est pas destin&eacute; &agrave; &ecirc;tre
      utilis&eacute; par une personne de moins de 16ans. Nous ne collecterons
      pas sciemment d&apos;informations ni ne fournirons de Services &agrave;
      une personne de moins de 16ans.
    </p>
    <p class="p4"><br /></p>
    <p class="p4"><br /></p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Achats</strong></p>
    <p class="p2">
      Edays offre certaines fonctionnalit&eacute;s am&eacute;lior&eacute;es des
      Services que vous pouvez acheter sous forme d&apos;abonnement mensuel,
      annuel ou &agrave; vie (&laquo; Abonnement &raquo;). Une description des
      fonctionnalit&eacute;s associ&eacute;es aux Abonnements est disponible via
      les Services. Lorsque vous achetez un Abonnement ou un Produit (chacun,
      une &laquo;&nbsp;Transaction&nbsp;&raquo;), nous pouvons vous demander de
      fournir des informations suppl&eacute;mentaires relatives &agrave; votre
      Transaction, telles que votre num&eacute;ro de carte de cr&eacute;dit, la
      date d&apos;expiration de votre carte de cr&eacute;dit et votre ou vos
      adresse(s) pour la facturation et la livraison (ces informations,
      &laquo;&nbsp;Informations de paiement&nbsp;&raquo;). Vous d&eacute;clarez
      et garantissez que vous avez le droit l&eacute;gal d&apos;utiliser tous
      les modes de paiement repr&eacute;sent&eacute;s par ces informations de
      paiement. Les montants dus et payables par vous pour une Transaction via
      les Services vous seront pr&eacute;sent&eacute;s avant que vous ne passiez
      votre commande. Si vous choisissez d&apos;initier une Transaction via les
      Services, vous nous autorisez &agrave; fournir vos informations de
      paiement &agrave; des prestataires de services tiers afin que nous
      puissions finaliser votre transaction et accepter (a) de payer les frais
      applicables et les &eacute;ventuelles taxes&nbsp;; (b) que Edays peut
      d&eacute;biter votre carte de cr&eacute;dit ou votre compte de traitement
      de paiement tiers, y compris, mais sans s&apos;y limiter, votre compte
      aupr&egrave;s de l&apos;App Store ou de la plateforme de distribution
      (comme l&apos;App Store d&apos;Apple ou Google Play) o&ugrave;
      l&apos;Application est mise &agrave; disposition ( chacun, un
      &quot;fournisseur d&apos;application&quot;), &agrave; des fins de
      v&eacute;rification, de pr&eacute;-autorisation et de paiement&nbsp;; et
      (c) prendre en charge tous les frais suppl&eacute;mentaires que votre
      fournisseur d&apos;application, votre banque ou un autre fournisseur de
      services financiers pourrait vous facturer, ainsi que les taxes ou frais
      susceptibles de s&apos;appliquer &agrave; votre commande. Vous recevrez un
      e-mail ou un SMS de confirmation une fois que nous aurons confirm&eacute;
      le paiement de votre commande. Votre commande n&apos;engage pas Edays tant
      qu&apos;elle n&apos;a pas &eacute;t&eacute; accept&eacute;e et
      confirm&eacute;e par Edays. Tous les paiements effectu&eacute;s sont non
      remboursables et non transf&eacute;rables, sauf disposition expresse des
      pr&eacute;sentes Conditions. Si vous avez des pr&eacute;occupations ou des
      objections concernant les frais, vous acceptez de les soulever avec nous
      en premier et vous acceptez de ne pas annuler ou rejeter les frais de
      traitement de paiement par carte de cr&eacute;dit ou tiers &agrave; moins
      que vous n&apos;ayez fait une tentative raisonnable pour r&eacute;soudre
      le probl&egrave;me directement avec Edays.
    </p>
    <p class="p5"><br /></p>
    <p class="p1">
      <strong>Renouvellement automatique de l&apos;abonnement</strong>
    </p>
    <p class="p2">
      Les abonnements sont automatiquement renouvel&eacute;s jusqu&apos;&agrave;
      ce que vous r&eacute;siliiez ou annuliez l&apos;abonnement. Tous les
      montants sont payables et factur&eacute;s : (I) pour un achat unique (par
      exemple, Abonnement &agrave; vie), au moment o&ugrave; vous passez votre
      commande ; et (II) Pour les abonnements mensuels ou annuels, au
      d&eacute;but de l&apos;abonnement et, parce que chacun de ces abonnements
      se renouvelle automatiquement pour une p&eacute;riode
      suppl&eacute;mentaire &eacute;gale &agrave; la dur&eacute;e
      d&apos;expiration de l&apos;abonnement jusqu&apos;&agrave; ce que vous
      l&apos;annuliez, au moment de chaque renouvellement jusqu&apos;&agrave; ce
      que vous l&apos;annuliez , en utilisant les informations de paiement que
      vous avez fournies. Vous devez annuler votre Abonnement mensuel ou annuel
      avant son renouvellement pour &eacute;viter la facturation des frais de la
      prochaine p&eacute;riode d&apos;Abonnement.
    </p>
    <p class="p3"><br /></p>
    <p class="p1">
      <strong>Annulations et remboursements d&apos;abonnement</strong>
    </p>
    <p class="p2">
      En r&egrave;gle g&eacute;n&eacute;rale, tous les frais d&apos;achat ne
      sont pas remboursables et il n&apos;y a aucun remboursement ou
      cr&eacute;dit pour les p&eacute;riodes partiellement utilis&eacute;es.
      Vous pouvez annuler votre abonnement Edays &agrave; tout moment et vous
      continuerez &agrave; avoir acc&egrave;s au service Edays
      jusqu&apos;&agrave; la fin de votre p&eacute;riode de facturation. Les
      objections &agrave; un paiement d&eacute;j&agrave; effectu&eacute; ou les
      demandes d&apos;annulation doivent &ecirc;tre adress&eacute;es au
      <a href="/contact"><span class="s1">service client</span></a>
      si vous avez achet&eacute; votre abonnement via le site. Si vous achetez
      votre abonnement via un fournisseur d&apos;applications, vous pouvez
      annuler le renouvellement de votre abonnement &agrave; tout moment
      aupr&egrave;s du fournisseur d&apos;applications. Vous ne recevrez pas de
      remboursement pour les frais que vous avez d&eacute;j&agrave; pay&eacute;s
      pour votre p&eacute;riode d&apos;abonnement en cours et vous continuerez
      &agrave; recevoir les Services command&eacute;s jusqu&apos;&agrave; la fin
      de votre p&eacute;riode d&apos;abonnement en cours.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Tarification</strong></p>
    <p class="p2">
      Edays se r&eacute;serve le droit de modifier ses conditions tarifaires
      pour les abonnements &agrave; tout moment et Edays vous informera &agrave;
      l&apos;avance de l&apos;entr&eacute;e en vigueur de ces modifications. Les
      modifications des conditions tarifaires ne s&apos;appliqueront pas
      r&eacute;troactivement et ne s&apos;appliqueront qu&apos;aux
      renouvellements d&apos;abonnement apr&egrave;s que ces conditions
      tarifaires modifi&eacute;es vous auront &eacute;t&eacute;
      communiqu&eacute;es. Si vous n&apos;&ecirc;tes pas d&apos;accord avec les
      modifications apport&eacute;es aux conditions tarifaires de Edays, vous
      pouvez choisir de ne pas renouveler votre abonnement conform&eacute;ment
      &agrave; la section &quot;Annulations et remboursements
      d&apos;abonnement&quot;.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Indemnit&eacute;</strong></p>
    <p class="p2">
      Vous acceptez de d&eacute;fendre, d&apos;indemniser et de tenir la
      Soci&eacute;t&eacute;, ses dirigeants, administrateurs, partenaires,
      employ&eacute;s, sous-traitants, agents, conc&eacute;dants et
      fournisseurs, indemnes de et contre toute r&eacute;clamation, action ou
      demande, responsabilit&eacute; et r&egrave;glement, y compris, sans
      s&apos;y limiter, raisonnable les frais juridiques et comptables,
      r&eacute;sultant ou suppos&eacute;s r&eacute;sulter de votre utilisation
      du Service.<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p3"><br /></p>
    <p class="p2">
      <strong>AVIS DE NON-RESPONSABILIT&Eacute; :&nbsp;</strong>En utilisant le
      Service, vous acceptez express&eacute;ment que l&apos;utilisation du
      Service est &agrave; vos risques et p&eacute;rils. Le Service est fourni
      sur une base &laquo; TEL QUEL &raquo; et &laquo; tel que disponible
      &raquo;. Ni la Soci&eacute;t&eacute; ni ses dirigeants, administrateurs,
      gestionnaires, membres, employ&eacute;s, agents, fournisseurs de contenu
      tiers, concepteurs, sous-traitants, distributeurs, marchands, sponsors,
      conc&eacute;dants de licence ou similaires (collectivement, les
      &laquo;&nbsp;Affili&eacute;s&nbsp;&raquo;) ne garantissent que
      l&apos;utilisation du Service sera ininterrompu ou sans erreur. Ni la
      Soci&eacute;t&eacute; ni ses Affili&eacute;s ne garantissent
      l&apos;exactitude, l&apos;int&eacute;grit&eacute; ou
      l&apos;exhaustivit&eacute; du contenu fourni sur le Service ou des
      produits ou services propos&eacute;s &agrave; la vente sur le Service. En
      outre, la Soci&eacute;t&eacute; ne garantit pas que le contenu fourni sur
      le Service est applicable ou appropri&eacute; pour une utilisation dans
      des lieux en dehors de la France. La Soci&eacute;t&eacute; et ses
      Soci&eacute;t&eacute;s affili&eacute;es d&eacute;clinent
      express&eacute;ment toute garantie, expresse ou implicite, y compris, mais
      sans s&apos;y limiter, les garanties de titre, de qualit&eacute; marchande
      ou d&apos;ad&eacute;quation &agrave; un usage particulier. Aucun conseil
      oral ou information &eacute;crite donn&eacute; par la
      Soci&eacute;t&eacute; ou ses Affili&eacute;s ne cr&eacute;era de garantie.
      Certains &Eacute;tats n&apos;autorisent pas l&apos;exclusion ou la
      limitation de certaines garanties, de sorte que la limitation ou
      l&apos;exclusion ci-dessus peut ne pas s&apos;appliquer &agrave; vous.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Limitation de responsabilit&eacute;</strong></p>
    <p class="p2">
      Sauf l&agrave; o&ugrave; la loi l&apos;interdit, LA SOCI&Eacute;T&Eacute;
      OU SES AFFILI&Eacute;S NE SERONT EN AUCUN CAS RESPONSABLES DES DOMMAGES
      DIRECTS, INDIRECTS, ACCESSOIRES, SP&Eacute;CIAUX OU CONS&Eacute;CUTIFS
      R&Eacute;SULTANT DE VOTRE UTILISATION OU DE VOTRE INCAPACIT&Eacute;
      &Agrave; UTILISER LE SERVICE, Y COMPRIS, MAIS SANS S&apos;Y LIMITER, VOTRE
      CONFIANCE SUR TOUTE INFORMATION OBTENUE DU SERVICE. LA LIMITATION DE
      RESPONSABILIT&Eacute; SUSMENTIONN&Eacute;E S&apos;APPLIQUERA &Agrave;
      TOUTE ACTION, QU&apos;ELLE SOIT CONTRACTUELLE, D&Eacute;LICTUELLE OU TOUTE
      AUTRE R&Eacute;CLAMATION, M&Ecirc;ME SI UN REPR&Eacute;SENTANT
      AUTORIS&Eacute; DE LA SOCI&Eacute;T&Eacute; A &Eacute;T&Eacute;
      AVIS&Eacute; OU DEVRAIT AVOIR CONNAISSANCE DE LA POSSIBILIT&Eacute; DE
      TELS DOMMAGES. Si, nonobstant les autres dispositions des pr&eacute;sentes
      Conditions d&apos;utilisation, la Soci&eacute;t&eacute; est tenue
      responsable envers vous de tout dommage ou perte r&eacute;sultant de ou
      li&eacute; de quelque mani&egrave;re que ce soit &agrave; votre
      utilisation du Service, LA RESPONSABILIT&Eacute; DE LA
      SOCI&Eacute;T&Eacute; ET DE SES AFFILI&Eacute;S ne d&eacute;passera en
      aucun cas le montant des frais pay&eacute;s au cours de la p&eacute;riode
      de 12 mois pr&eacute;c&eacute;dente. Certaines juridictions
      n&apos;autorisant pas les limitations de responsabilit&eacute;, la
      limitation qui pr&eacute;c&egrave;de peut ne pas s&apos;appliquer &agrave;
      vous.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Droit applicable</strong></p>
    <p class="p2">
      Vous acceptez que toutes les questions relatives &agrave; votre
      acc&egrave;s ou &agrave; votre utilisation du Service, y compris tous les
      litiges, seront r&eacute;gies par les lois fran&ccedil;aises, sans
      &eacute;gard &agrave; ses dispositions en mati&egrave;re de conflits de
      lois. Vous acceptez la juridiction personnelle et le lieu des tribunaux
      d&apos;&Eacute;tat et f&eacute;d&eacute;raux de Nice, France, et renoncez
      &agrave; toute objection &agrave; cette juridiction ou &agrave; ce
      lieu.<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p2">
      &zwj;RENONCIATION<strong
        >&nbsp;AU PROC&Egrave;S PAR JURY. SAUF O&Ugrave; LA LOI L&apos;INTERDIT,
        VOUS RENONCEZ EXPRESS&Eacute;MENT &Agrave; UN PROC&Egrave;S PAR JURY
        DANS TOUTE PROC&Eacute;DURE JUDICIAIRE IMPLIQUANT TOUT DIFF&Eacute;REND,
        CONTROVERSE OU R&Eacute;CLAMATION D&Eacute;COULANT DES PR&Eacute;SENTES
        CONDITIONS G&Eacute;N&Eacute;RALES OU DU SERVICE.</strong
      >
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Aucune renonciation</strong></p>
    <p class="p2">
      L&apos;incapacit&eacute; de la Soci&eacute;t&eacute; &agrave; insister sur
      le strict respect des pr&eacute;sentes Conditions d&apos;utilisation ou
      &agrave; les faire respecter ne sera pas interpr&eacute;t&eacute;e comme
      une renonciation par la Soci&eacute;t&eacute; &agrave; toute disposition
      ou &agrave; tout droit dont elle dispose pour faire respecter les
      pr&eacute;sentes Conditions d&apos;utilisation, ni aucune ligne de
      conduite entre le La Soci&eacute;t&eacute; et vous ou toute autre partie
      seront r&eacute;put&eacute;s modifier toute disposition des
      pr&eacute;sentes Conditions d&apos;utilisation.
    </p>
    <p class="p4"><br /></p>
    <p class="p1"><strong>Divisibilit&eacute;</strong></p>
    <p class="p2">
      Les dispositions des pr&eacute;sentes Conditions d&apos;utilisation sont
      dissociables. L&apos;invalidit&eacute;, en tout ou en partie, de toute
      disposition des pr&eacute;sentes conditions d&apos;utilisation
      n&apos;affectera pas la validit&eacute; ou l&apos;applicabilit&eacute; de
      toute autre de ses dispositions. Si l&apos;une des dispositions des
      pr&eacute;sentes conditions d&apos;utilisation est jug&eacute;e nulle ou
      inapplicable par un tribunal ou un autre tribunal comp&eacute;tent, ces
      dispositions seront limit&eacute;es ou supprim&eacute;es dans la mesure
      minimale n&eacute;cessaire et remplac&eacute;es par une disposition valide
      qui incarne le mieux l&apos;intention des pr&eacute;sentes conditions
      d&apos;utilisation, afin que ces conditions d&apos;utilisation restent
      pleinement en vigueur.
    </p>
    <p class="p2">En vigueur : 08 Mai 2022</p>
    <p class="p4"><br /></p>
  </div>
</template>
<script>
export default {};
</script>

<style type="text/css">
p.p1 {
  margin: 0px 0px 2px 0px;
  font: 16px Helvetica;
  color: #000000;
}

p.p2 {
  margin: 0px 0px 0px 0px;
  font: 13px Helvetica;
  color: #000000;
}

p.p3 {
  margin: 0px 0px 0px 0px;
  font: 13px Helvetica;
  color: #000000;
  min-height: 16px;
}

p.p4 {
  margin: 0px 0px 2px 0px;
  font: 16px Helvetica;
  color: #000000;
  min-height: 19px;
}

p.p5 {
  margin: 0px 0px 2px 0px;
  font: 16px Helvetica;
  color: #fb0007;
  min-height: 19px;
}

li.li2 {
  margin: 0px 0px 0px 0px;
  font: 13px Helvetica;
  color: #000000;
}

span.s1 {
  text-decoration: underline;
  color: #d2910f;
}

span.s2 {
  color: #fb0007;
}

ul.ul1 {
  list-style-type: disc;
}
</style>
